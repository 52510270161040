<template>
  <div class="bg">
    <!-- <p>
      <strong style="font-weight: 900">Pay Online Disclaimer:</strong> You are
      being re-directed to a third-partysite.By clicking on the link “Buy Now”
      here, you are accepting this Pay OnlineDisclaimer and your automatic
      redirection to a third-party websitei.e. the website of Razorpay
      (hereinafter "Website") using which youmay make payments. The Website is
      not owned, controlled orendorsed by Moonfrog Labs Private Limited, its
      affiliates,subsidiaries, and each of their employees, officers,
      directors,personnel or agents (hereinafter "Moonfrog").Moonfrog does not
      warrant the accuracy or completeness of theservices, information,
      materials, tools or the reliability of anyservice, advice, opinion,
      statement or other information displayed ordistributed through the
      Website. Furthermore, you are advised tomake your own enquiries and
      arrangements to verify, scan andcheck the Website for any viruses,
      malicious code or malware, andMoonfrog provides no warranties whatsoever
      in this regard. Youunderstand and acknowledge that your access of the
      Website andyour availing of any services offered on the Website or
      reliance onany opinion, statement, or information available on the
      Websiteshall be at your sole risk. The information and services available
      onthe Website are subject to change, updation, revision, verificationand
      amendment without notice and such services and informationmay change
      materially at any time, and you agree and accept thesame.Moonfrog
      expressly disclaims any and all liability or responsibilitywhatsoever with
      respect to the Website you access, including, butnot limited to any
      information displayed or distributed on/ by theWebsite, deficiency in
      services of BillDesk, failure / disruption /inability to access the
      Website, failure to make payments, technicalerrors, loss or theft of data,
      erroneous submission of data orinformation, or any virus, malware or
      phishing attacks, whether ornot resulting from any act or omission of
      Moonfrog, or BillDesk orany other party involved in the functioning of the
      Website or in theservices contained therein. Your usage of the Website is
      subject toyour compliance of the applicable laws, regulations and
      allapplicable terms &amp; conditions.*NOTE: Payments made using the
      Website may ordinarily take 2business days to reach Moonfrog, and will be
      recorded by Moonfrogonly as on the date they are fully received by
      Moonfrog fromBillDesk. A business day shall end at 16:00 hrs - Monday to
      Friday(excluding weekends and public holidays).
    </p> -->

    <p
      class="MsoListParagraph"
      style="
        margin-right: 8.35pt;
        margin-bottom: 0in;
        margin-left: 21.3pt;
        margin-bottom: 0.0001pt;
        text-indent: -3.3pt;
        line-height: 111%;
        punctuation-wrap: simple;
      "
    >
      <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626">
        <span style="font: 7pt 'Times New Roman'">
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
      <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626">
        <strong style="font-weight: 900">Pay Online Disclaimer:</strong>
        <br />
        You are being re-directed to a third-party site. By clicking on the link
        “Buy Now” here, you are accepting this Pay Online Disclaimer and your
        automatic redirection to a third-party website i.e. the website of
        Razorpay (hereinafter "Website") using which you may make payments. The
        Website is not owned, controlled orendorsed by Moonfrog Labs Private
        Limited, its affiliates, subsidiaries, and each of their employees,
        officers, directors,personnel or agents (hereinafter "Moonfrog").
        Moonfrog does not warrant the accuracy or completeness of theservices,
        information, materials, tools or the reliability of anyservice, advice,
        opinion, statement or other information displayed or distributed through
        the Website. Furthermore, you are advised to make your own enquiries and
        arrangements to verify, scan and check the Website for any viruses,
        malicious code or malware, and Moonfrog provides no warranties
        whatsoever in this regard. You understand and acknowledge that your
        access of the Website andyour availing of any services offered on the
        Website or reliance onany opinion, statement, or information available
        on the Website shall be at your sole risk. The information and services
        available onthe Website are subject to change, updation, revision,
        verificationand amendment without notice and such services and
        information may change materially at any time, and you agree and accept
        the same. Moonfrog expressly disclaims any and all liability or
        responsibilitywhatsoever with respect to the Website you access,
        including, but not limited to any information displayed or distributed
        on/ by theWebsite, deficiency in services of Razorpay, failure /
        disruption /inability to access the Website, failure to make payments,
        technical errors, loss or theft of data, erroneous submission of data or
        information, or any virus, malware or phishing attacks, whether or not
        resulting from any act or omission of Moonfrog, or Razorpay or any other
        party involved in the functioning of the Website or in theservices
        contained therein. Your usage of the Website is subject to your
        compliance of the applicable laws, regulations and all applicable terms
        &amp; conditions.*NOTE: Payments made using the Website may ordinarily
        take 2 business days to reach Moonfrog, and will be recorded by Moonfrog
        only as on the date they are fully received by Moonfrog from Razorpay. A
        business day shall end at 16:00 hrs - Monday to Friday(excluding
        weekends and public holidays).
      </span>
      <br />
      <br />
      <span lang="EN-IN" style="font-family: 'Helvetica Neue'; color: #262626">
        <strong style="font-weight: 900">No Refund Policy:</strong>
        <br />
        Moonfrog digital product have a strict no-refund policy. Please be sure
        the products are right for you before purchasing. Users are solely
        responsible for confirming that their devices are compatible with the
        Products they purchase. All virtual item purchased are final,
        non-refundable and non-returnable. We do not offer refunds or exchanges
        for the incorrect purchase of Moonforg Products, including due to
        compatibility issues.
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: "PaymentTnC",
  components: {},
  props: {},
  data() {
    return {};
  },
};
</script>
<style scoped>
p {
  margin-right: 0in;
  margin-left: 0in;
  font-size: 12pt;
  font-family: "Times New Roman", serif;
}
p.MsoListParagraph {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 7.6pt;
  text-align: justify;
  text-autospace: none;
  font-size: 12pt;
  font-family: "Calibri", sans-serif;
}
.bg {
  background: white;
  min-height: 100vh;
  max-width: none;
  padding: 2rem;
}
</style>